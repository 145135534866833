/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import {Helmet} from "react-helmet";

import Header from "./header"
import "./layout.css"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <Helmet>
                <title>HUGS - Haus- und Grundstücksservice Christian Eckl</title>
                <meta name="description"
                      content="■ Gewerbliche Objektbetreuung ■ Pflege von Haus | Grund | Garten ■ Renovierungs- & Reperaturarbeiten ■ Be- & Entsorgungsfahrten uvm."/>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>
                <meta name="msapplication-TileColor" content="#da532c"/>
                <meta name="theme-color" content="#ffffff"/>
                <html lang="de"/>
                <link href="https://fonts.googleapis.com/css?family=Poppins:300&display=swap" rel="stylesheet"/>

                <meta property="og:site_name" content="HUGS - Haus- und Grundstücksservice Christian Eckl"/>
                <meta property="og:title" content="HUGS - Haus- und Grundstücksservice Christian Eckl"/>
                <meta property="og:description" content="■ Gewerbliche Objektbetreuung ■ Pflege von Haus | Grund | Garten ■ Renovierungs- & Reperaturarbeiten ■ Be- & Entsorgungsfahrten uvm."/>

            </Helmet>
            <main>{children}</main>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
